//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import kirbylink from '../kirbylink.vue'
export default {
  components: { kirbylink },
    name: "imagectaDefault",
    props: { 
        content: Object,
        showCta: Boolean,
        imageurl: String,
        caption: String,

    },
    data: function () { 
        return {
            paintstroke: this.content.showpaintstroke === 'true' ? "paintstroke" : ""
        }
    }
}
